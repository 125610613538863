import $ from 'jquery';
(<any>window).jQuery = $;

export class Confetti {

  private maxParticleCount = 500;
  private particleSpeed = 2;
  private startConfetti;
  private stopConfetti;
  private toggleConfetti;
  private removeConfetti;

  private colors;
  private streamingConfetti;
  private animationTimer;
  private particles;
  private waveAngle;

  constructor() {
    (<any>window).confettiClass = this;

    this.startConfetti = this.startConfettiInner;
    this.stopConfetti = this.stopConfettiInner;
    this.toggleConfetti = this.toggleConfettiInner;
    this.removeConfetti = this.removeConfettiInner;

    this.colors = ["#f75d1b","#ffffff","#ff9567"];

    this.streamingConfetti = false;
    this.animationTimer = null;
    this.particles = [];
    this.waveAngle = 0;
  }

  resetParticle(particle, width, height) {
    particle.color = this.colors[(Math.random() * this.colors.length) | 0];
    particle.x = Math.random() * width;
    particle.y = Math.random() * height - height;
    particle.diameter = Math.random() * 10 + 5;
    particle.tilt = Math.random() * 10 - 10;
    particle.tiltAngleIncrement = Math.random() * 0.07 + 0.05;
    particle.tiltAngle = 0;
    return particle;
  }

  startConfettiInner() {
    var width = $($(".wrapper")[0]).width(); //window.innerWidth;
    var height = window.innerHeight;
    (<any>window).requestAnimFrameC = (function () { return window.requestAnimationFrame || window.webkitRequestAnimationFrame || function (callback) { return window.setTimeout(callback, 16.6666667); }; })();
    var canvas = <HTMLCanvasElement>document.getElementById("confetti-canvas");
    // window.addEventListener("resize", function () { $(canvas).css("width", window.innerWidth); $(canvas).css("height", window.innerHeight); $(canvas).css("top", $(window).scrollTop() - $("MAIN").offset().top ); }, true);
    // window.addEventListener("scroll", function () { $(canvas).css("top", $(window).scrollTop() - $("MAIN").offset().top ); }, true);
    // $(canvas).css("top", $(window).scrollTop() - $("MAIN").offset().top );

    var context = canvas.getContext("2d");
    while (this.particles.length < this.maxParticleCount) {
      this.particles.push(this.resetParticle({}, width, height)); this.streamingConfetti = true; if (this.animationTimer === null) {
        (function runAnimation() {
          context.clearRect(0, 0, window.innerWidth, window.innerHeight);
          if ((<any>window).confettiClass.particles.length === 0) {
            (<any>window).confettiClass.animationTimer = null;
          } else {
            (<any>window).confettiClass.updateParticles(); (<any>window).confettiClass.drawParticles(context);
            (<any>window).confettiClass.animationTimer = (<any>window).requestAnimFrameC(runAnimation);
          }
        })();
      }
    }

    window.setTimeout(
      function(){
        (<any>window).confettiClass.stopConfetti();
      }, 1500
    );
  }

  stopConfettiInner() {
    this.streamingConfetti = false;
  }

  removeConfettiInner() {
    this.stopConfetti();
    this.particles = [];
  }

  toggleConfettiInner() {
    if (this.streamingConfetti)
      this.stopConfettiInner();
    else
      this.startConfettiInner();
  }

  drawParticles(context) {
    var particle;
    var x;
    for (var i = 0; i < this.particles.length; i++) {
      particle = this.particles[i];
      context.beginPath();
      context.lineWidth = particle.diameter;
      context.strokeStyle = particle.color;
      x = particle.x + particle.tilt;
      context.moveTo(x + particle.diameter / 2, particle.y);
      context.lineTo(x, particle.y + particle.tilt + particle.diameter / 2);
      context.stroke();
    }
  }

  updateParticles() {
    var width = $($(".wrapper")[0]).width(); // window.innerWidth;
    var height = window.innerHeight;
    var particle;
    this.waveAngle += 0.01;
    for (var i = 0; i < this.particles.length; i++) {
      particle = this.particles[i];
      if (!this.streamingConfetti && particle.y < -15) {
        particle.y = height + 100;
      } else {
        particle.tiltAngle += particle.tiltAngleIncrement;
        particle.x += Math.sin(this.waveAngle);
        particle.y += (Math.cos(this.waveAngle) + particle.diameter + this.particleSpeed) * 0.5;
        particle.tilt = Math.sin(particle.tiltAngle) * 15;
      }
      if (particle.x > width + 20 || particle.x < -20 || particle.y > height) {
        if (this.streamingConfetti && this.particles.length <= this.maxParticleCount)
          this.resetParticle(particle, width, height); else { this.particles.splice(i, 1); i--; }
      }
    }
  }

}