import $ from 'jquery';

/**
 * Validation of the form and sending the data via AJAX.
 * The result will open a corresponding modal, depending on the servers response.
 */
export class ValidatorFreeTicket {

  constructor() {
    $(() => {
      if ($('#free-ticket-form').length > 0) {

        let $submitBtn = $('#free-ticket-form BUTTON[type=submit]');
        if ($submitBtn.hasClass('disabled')) {
          $submitBtn.click(function (e) {
            e.preventDefault();
            return false;
          });
          return
        }

        this.addListeners();
        this.checkAll();
      }
    })

  }

  inputHandler = (e: JQuery.EventBase): void => {
    const input = e.currentTarget as HTMLInputElement;
    const $input = $(input);
    const val = $input.val();

    var textOutout = 'Bitte fülle dieses Feld aus!';

    input.setCustomValidity(val.toString().length > 0 ? '' : textOutout);
    $(input).attr("title", val.toString().length > 0 ? '' : textOutout);
  }

  uploadFilesChangeHandler = (evt): void => {
    $('#file_list').children().remove();

    const files = evt.target.files;
    evt.target.setCustomValidity(evt.target.files.length ? '' : 'Bitte wählen Sie eine Datei aus.');

    for (let i = 0, file; file = files[i]; ++i) {

      if (file.type.match('image.*')) {
        if (file.size > 5 * 1024 * 1000) {
          evt.target.setCustomValidity('Die Datei ist zu groß');
          continue;
        }

        // this.addImageThumbnail(file);
        continue;

      } else if (file.type.match('application/pdf')) {
        if (file.size > 5 * 1024 * 1000) {
          evt.target.setCustomValidity('Die Datei ist zu groß');
          continue;
        }

        // this.addPDFPreview(file);
        continue;

      } else {
        evt.target.setCustomValidity('Der Dateityp wird leider nicht unterstützt');
      }
    }
  }

  checkAll = () => {
    $('#free-ticket-form').addClass('invalidform');

    if ($('#submitbtn').hasClass('disabled'))
      return false;

    $('input[type=text],select').trigger('change');
  }

  clearUploadFiles = () => {
    $('#upload_files').val(null);
    $('#upload_files').trigger('change');
    $('#file_list').children().remove();
  }

  addListeners = () => {
    $('#free-ticket-email').on('input change', this.inputHandler);
    $('#upload_files').on('change', this.uploadFilesChangeHandler);
    $(document).on('click', '.btn-remove-uploads', () => this.clearUploadFiles());

    $('#free-ticket-form BUTTON[type=submit]').on('click', this.submitButtonClickHandler);
    $('#free-ticket-form').on('submit', this.submitFreeTicketFormHandler);
  }

  removeListeners = () => {
    $('#free-ticket-email').off('input change', this.inputHandler);
    $('#upload_files').off('change', this.uploadFilesChangeHandler);
    $(document).off('click', '.btn-remove-uploads', () => this.clearUploadFiles());

    $('#free-ticket-form BUTTON[type=submit]').off('click', this.submitButtonClickHandler);
    $('#free-ticket-form').off('submit', this.submitFreeTicketFormHandler);
  }

  submitPressed = false;

  submitButtonClickHandler = (e: JQuery.Event): void => {
    this.submitPressed = true;
    this.checkAll();
  }

  submitFreeTicketFormHandler = (e: JQuery.Event): boolean => {
    e.preventDefault();

    const form = (e as JQuery.EventBase).currentTarget;
    const $form = $(form);
    $('.modal').modal('hide');

    try {
      const $uploadModal = $('#uploadModal');

      $.ajax({
        url: $form.attr('action'),
        data: new FormData(form),
        method: $form.attr("method"),
        type: $form.attr("method"),
        dataType: "JSON",
        contentType: false,
        processData: false,

        xhr: () => {
          setTimeout(() => {
            $uploadModal.modal('show').css({
              // top: -(window['viewOffset'].ty) + 'px'
            });
          }, 0); // NOTE: the timeout is important to keep bootstraps class management on the body tag intact!

          const xhr = jQuery.ajaxSettings.xhr();

          if (xhr.upload) {
            xhr.upload.onprogress = (e) => {
              let value = Math.floor(100 * e.loaded / e.total).toFixed(2);
              $('#uploadProgress').val(value);
              $('#uploadProgress .progress-bar').attr('aria-valuenow', value).css('width', `${value}%`);
              $('#uploadProgressValue').text(`${value} %`);
            }
          }

          return xhr;
        },

        success: (data) => {
          if (data.status === 'error') {
            let errors = '';
            for (const key in data.details) {
              errors += `<li class="error-list-item">${data.details[key]}</li>`;
            }
            errors = `<div class="errors"><ul class="error-list">${errors}</ul></div>`;
            const html = data.messageText ? `<div>${data.messageText}</div>${errors}` : `${errors}`;
            this.feedbackModal(data.message, $(html));
          }
          else if (data.status === 'success') {
            this.feedbackModal(data.message, $(`<p>${data.messageText ? data.messageText : ''}</p>`));
            this.clearUploadFiles();
          }
        },
        error: (error) => {
          this.feedbackModal(`${error.statusText} ${error.status}`, $(`<p>${error.responseText}</p>`));
        },
        complete: () => {
          $('[data-captcha-container]').trigger('refresh');
          $uploadModal.modal('hide');
        }
      });
    } catch (err) {
      this.feedbackModal('Upload Error', $(err.toString()));
    }

    return false;
  };

  feedbackModal = (title: string = "", html: JQuery<HTMLElement>, closeAllModals: boolean = false): void => {
    if (closeAllModals) $('.modal').modal('hide');
    let $modal = $('#feedbackModal');
    $modal.find('.modal-title').text(title);
    $modal.find('.modal-body').html('');
    $modal.find('.modal-body').append(html);
    setTimeout(() => {
      $modal.modal('show').css({
      //   top: -(window['viewOffset'].ty) + 'px'
      });
    }, 200); // NOTE: the timeout is important to keep bootstraps class management on the body tag intact!
  }

}
