import $ from 'jquery';

/**
 * Validation of the form and sending the data via AJAX.
 * The result will open a corresponding modal, depending on the servers response.
 */
export class ValidatorParticipation {

  constructor() {
    $(() => {
      if ($('#participation-form').length > 0) {

        let $submitBtn = $('#participation-form BUTTON[type=submit]');
        if ($submitBtn.hasClass('disabled')) {
          $submitBtn.click(function (e) {
            e.preventDefault();
            return false;
          });
          return
        }

        this.addListeners();
        this.checkAll();
      }
    })

  }

  inputHandler = (e: JQuery.EventBase): void => {
    const input = e.currentTarget as HTMLInputElement;
    const $input = $(input);
    const val = $input.val();

    var textOutout = 'Bitte fülle dieses Feld aus!';

    input.setCustomValidity(val.toString().length > 0 ? '' : textOutout);
    $(input).attr("title", val.toString().length > 0 ? '' : textOutout);
  }

  emailSyncHandler = (e: JQuery.EventBase): void => {
    const input = e.currentTarget as HTMLInputElement;
    const $input = $(input);
    const val = $input.val();

    var textOutout = 'Die E-Mail-Adressen müssen übereinstimmen!';

    var validity = $('#email').val().toString() === val.toString() ? '' : textOutout;

    input.setCustomValidity(validity);
    $(input).attr("title", validity);
  }

  inputZipHandler = (e: JQuery.EventBase): void => {
    const input = e.currentTarget as HTMLInputElement;
    const $input = $(input);
    const val = $input.val();

    var textOutout = 'Bitte fülle dieses Feld aus!';
    var textOutout2 = 'Bitte gib eine gültige 4-stellige Postleitzahl ein!';

    if( val.toString().length <= 0 ){
      input.setCustomValidity(textOutout);
      $(input).attr("title",textOutout);
    }else if( isNaN((+val)) || String(val).length !== 4 || Number(val) < 1000  ){
      input.setCustomValidity(textOutout2);
      $(input).attr("title",textOutout2);
    }else{
      input.setCustomValidity(val.toString().length > 0 ? '' : textOutout);
      $(input).attr("title", val.toString().length > 0 ? '' : textOutout);
    }
  }

  checkSelectValidity = (e: JQuery.EventBase): void => {
    var textOutout = 'Bitte wähle einen Eintrag in der Liste aus!';

    (e.currentTarget as HTMLInputElement).setCustomValidity($(e.currentTarget).prop('selectedIndex') > 0 ? '' : textOutout);
  }

  selectOptionIsValid = ($obj: JQuery): void => {
    var textOutout = 'Bitte wähle einen Eintrag in der Liste aus!';

    if ($obj.length)
      ($obj[0] as HTMLInputElement).setCustomValidity($obj.prop('selectedIndex') > 0 ? '' : textOutout);
  }

  checkAll = () => {
    $('#participation-form').addClass('invalidform');

    if ($('#submitbtn').hasClass('disabled'))
      return false;

    $('input[type=text],select').trigger('change');
  }

  addListeners = () => {
    $('#title').on('change', this.checkSelectValidity);
    $('#first_name').on('input change', this.inputHandler);
    $('#last_name').on('input change', this.inputHandler);
    $('#email').on('input change', this.inputHandler);
    $('#email2').on('input change', this.inputHandler);
    $('#email2').on('input change', this.emailSyncHandler);

    $('#participation-form BUTTON[type=submit]').on('click', this.submitButtonClickHandler);
    $('#participation-form').on('submit', this.submitRecordwinnerHandler);
  }

  removeListeners = () => {
    $('#title').off('change', this.checkSelectValidity);
    $('#first_name').off('input change', this.inputHandler);
    $('#last_name').off('input change', this.inputHandler);
    $('#email').off('input change', this.inputHandler);
    $('#email2').off('input change', this.inputHandler);
    $('#email2').off('input change', this.emailSyncHandler);

    $('#participation-form BUTTON[type=submit]').off('click', this.submitButtonClickHandler);
    $('#participation-form').off('submit', this.submitRecordwinnerHandler);
  }

  submitPressed = false;

  submitButtonClickHandler = (e: JQuery.Event): void => {
    this.submitPressed = true;
    this.checkAll();
  }

  submitRecordwinnerHandler = (e: JQuery.Event): boolean => {
    e.preventDefault();

    const form = (e as JQuery.EventBase).currentTarget;
    const $form = $(form);

    try {
      const fd = new FormData(form);

      $.ajax({
        url: $form.attr('action'),
        data: fd,
        method: $form.attr("method"),
        type: $form.attr("method"),
        dataType: "JSON",
        contentType: false,
        processData: false,

        success: (data) => {
          if (data.status === 'error') {
            let errors = '';
            for (const key in data.details) {
              errors += `<li class="error-list-item">${data.details[key]}</li>`;
            }
            errors = `<div class="errors"><ul class="error-list">${errors}</ul></div>`;
            const html = data.messageText ? `<div>${data.messageText}</div>${errors}` : `${errors}`;
            this.feedbackModal(data.feedbackTitle, $(html));
          } else if (data.status === 'success') {
            this.confirmModal();
          }
        },
        error: (error) => {
          this.feedbackModal(`${error.statusText} ${error.status}`, $(`${error.responseText}`));
        },
        complete: () => {
          $('[data-captcha-container]').trigger('refresh');
        }
      });
    } catch (err) {
      var errorOutput = 'Es ist ein Fehler aufgetreten';
      this.feedbackModal(errorOutput, $(err.toString()));
    }

    return false;
  };

  feedbackModal = (title: string = "", html: JQuery<HTMLElement>, closeAllModals: boolean = false): void => {
    if (closeAllModals) $('.modal').modal('hide');
    let $modal = $('#feedbackModal');
    $modal.find('.modal-title').text(title);
    $modal.find('.modal-body').html('');
    $modal.find('.modal-body').append(html);
    setTimeout(() => {
      $modal.modal('show');
    }, 0); // NOTE: the timeout is important to keep bootstraps class management on the body tag intact!
  }

  confirmModal = (): void => {
    let $modal = $('#confirmModal');
    setTimeout(() => {
      $modal.modal('show');
      $("#participateModal").fadeOut(10, function () {
        $(this).modal('hide')
      });
    }, 0); // NOTE: the timeout is important to keep bootstraps class management on the body tag intact!
  }

}
