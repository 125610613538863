import $ from 'jquery';

/**
 * Adds the initializing/refreshing logic for the captcha component
 */
export class Captcha {

  private _selector: string;
  private _url: string;

  constructor(selector, url) {
    this._selector = selector;
    this._url = url;

    (<any>window).captchaClass = this;
    $(document).ready(() => this.refresh());
    $(document).on('refresh', '[data-captcha-container]', () => this.refresh());
    $(document).on('input change', '[data-captcha-input]', (e: JQuery.UIEventBase) => this.inputHandler(e));
    $(document).on('click', '[href="/refreshCaptcha"]', (e: JQuery.UIEventBase) => {
      e.preventDefault();
      $(e.currentTarget).parentsUntil('[data-captcha-container]').last().trigger('refresh');
    });
  }

  refresh() {
    const $el = $(this._selector);
    $el.addClass('loading');

    $.ajax({
      url: this._url + '?cb=' + btoa((Math.random() * 0x1fffffff + 0x1fffffff).toString() + (new Date()).valueOf()),
      success: (data) => {
        $el.children().remove();
        $el.append($(data + '<div class="captcha-refresh-link"><a href="/refreshCaptcha">Captcha-Code neu laden</a></div>'));
      },
      complete: () => {
        $el.removeClass('loading');
        $el.find('[data-captcha-input]').trigger('input');
      }
    });
  }

  inputHandler = (e: JQuery.UIEventBase): void => {
    let msg = '';
    const $input = $(e.currentTarget);
    const val = $input.val() as string;
    const min = parseInt($input.attr('minlength'));
    const max = parseInt($input.attr('maxlength'));
    if (val.length > max) msg = `Bitte gib nicht mehr als ${max} Zeichen ein.`;
    else if (val.length < min) msg = `Bitte gib alle ${min} Stellen der Zeichenfolge ein.`;
    if (val.length === 0) msg = `Bitte fülle dieses Feld aus.`;
    e.currentTarget.setCustomValidity(msg);
  }
}