import $ from 'jquery';

/**
 * Validation of the form and sending the data via AJAX.
 * The result will open a corresponding modal, depending on the servers response.
 */
export class Validator {

  constructor() {
    $(document).ready(() => {
      if ($('#checkcode-form').length > 0) {

        let $submitBtn = $('#checkcode-form BUTTON[type=submit]');
        if ($submitBtn.hasClass('disabled')) {
          $submitBtn.click(function (e) {
            e.preventDefault();
            return false;
          });
          return
        }

        this.addListeners();
        this.checkAll();
      }
    })

  }

  inputCodeHandler = (e: JQuery.EventBase): void => {
    const input = e.currentTarget as HTMLInputElement;
    const $input = $(input);
    const val = $input.val();

    var textOutout = 'Bitte gib einen 6-stelligen Code ein!';

    if (val.toString().length !== 6) {
      input.setCustomValidity(textOutout);
      $(input).attr("title", textOutout);
    } else {
      input.setCustomValidity("");
      $(input).attr("title", "");
    }
  }

  checkAll = () => {
    $('#checkcode-form').addClass('invalidform');

    if ($('#submitbtn').hasClass('disabled'))
      return false;

    $('input[type=text],input[type=email],select').trigger('change');
  }

  addListeners = () => {
    $('#code').on('input change', this.inputCodeHandler);
    $('#checkcode-form BUTTON[type=submit]').on('click', this.submitButtonClickHandler);
    $('#checkcode-form').on('submit', this.submitParticipationHandler);
  }

  removeListeners = () => {
    $('#code').off('input change', this.inputCodeHandler);
    $('#checkcode-form BUTTON[type=submit]').off('click', this.submitButtonClickHandler);
    $('#checkcode-form').off('submit', this.submitParticipationHandler);
  }

  submitPressed = false;

  submitButtonClickHandler = (e: JQuery.Event): void => {
    this.submitPressed = true;
    this.checkAll();
  }

  submitParticipationHandler = (e: JQuery.Event): boolean => {
    e.preventDefault();

    const form = (e as JQuery.EventBase).currentTarget;
    const $form = $(form);

    try {
      const fd = new FormData(form);

      $.ajax({
        url: $form.attr('action'),
        data: fd,
        method: $form.attr("method"),
        type: $form.attr("method"),
        dataType: "JSON",
        contentType: false,
        processData: false,

        success: (data) => {
          if (data.status === 'error') {
            if (data.code == -3 || data.code == -4) {
              const html = data.message ? `<div>${data.message}</div>` : `<div>Bitte versuche es noch einmal.</div>`;
              const title = data.messageTitle ? `${data.messageTitle}` : `Es ist ein Fehler aufgetreten.`;
              this.feedbackModal(title, $(html));
            } else {
              let errors = '';
              for (const key in data.details) {
                errors += `<li class="error-list-item">${data.details[key]}</li>`;
              }
              errors = `<div class="errors"><ul class="error-list">${errors}</ul></div>`;
              const html = data.messageText ? `<div>${data.messageText}</div>${errors}` : `${errors}`;
              this.feedbackModal(data.feedbackTitle, $(html));
            }
          } else if (data.status === 'success') {
            $('#participationTokenId').val(data.participationToken); // set participation token
            // Torwand
            (<any>window).initTorwandGame();
          }
        },
        error: (error) => {
          this.feedbackModal(`${error.statusText} ${error.status}`, $(`${error.responseText}`));
        },
        complete: () => {
          $('[data-captcha-container]').trigger('refresh');
        }
      });
    } catch (err) {
      var errorOutput = 'Es ist ein Fehler aufgetreten';
      this.feedbackModal(errorOutput, $(err.toString()));
    }

    return false;
  };

  feedbackModal = (title: string = "", html: JQuery<HTMLElement>, closeAllModals: boolean = false): void => {
    if (closeAllModals) $('.modal').modal('hide');
    let $modal = $('#feedbackModal');
    $modal.find('.modal-title').text(title);
    $modal.find('.modal-body').html('');
    $modal.find('.modal-body').append(html);
    setTimeout(() => {
      $modal.modal('show');
    }, 0); // NOTE: the timeout is important to keep bootstraps class management on the body tag intact!
  }

  alreadyModal = (): void => {
    let $modal = $('#alreadyModal');
    setTimeout(() => {
      $modal.modal('show');
    }, 0); // NOTE: the timeout is important to keep bootstraps class management on the body tag intact!
  }

}
