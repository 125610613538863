import $ from 'jquery';
(<any>window).jQuery = $;

import * as CANNON from "cannon";
import { Howl, Howler } from "howler";

export class Torwand {

  private sfxMuted = true;
  private frameNumber = 0;
  private goalIsHit = null;
  private sfxBgr = null;
  private sfxBgr2 = null;
  private sfxBall = null;
  private sfxOuch = null;
  private sfxNoGoal = null;
  private sfxWhistle = null;
  private probeModus = false;
  private shotsTotal = 6;
  private shotsMade = [];
  private shotsGood = 0;
  private shotsBad = 0;
  private currentShotWronghole = false;

  //////// THE GAME ////////

  private world;
  private SUB_STEPS = 10;
  private dt = 1 / 60 / this.SUB_STEPS; // delta time for physic calculation step

  // To be synced
  private meshes = [];
  private bodies = [];

  private FORCE = 32; // ~120 km/h
  private ballRadius = 0.22 * 0.5;

  // auto aiming parameters
  private autoAimAmount = 1;
  private autoAimDistanceAngular = 9; // 7
  private autoAimDistanceVelocity = 0.399;  // 0.333
  private autoAimCurveAngle = .155; // .165
  private autoAimCurveVelocity = .05; // .05

  private ballBody;
  private goalHitBodyLT;
  private goalHitBodyRB;
  private goalWallBody;

  private angle = Math.PI * .5;
  private velocity = 0;
  private ballRotation = 0;

  private lastWallHit = 0;
  private touch;
  private ballResetTimeout;

  // html elements
  private game = document.querySelector(".game-container");
  private goalPivot = this.game.querySelector(".goal-pivot");
  private goal = this.game.querySelector(".goal");
  private ballPivot = this.game.querySelector(".ball-pivot");
  private ball = this.game.querySelector(".ball");
  private shadowPivot = this.game.querySelector(".ball-shadow-pivot");
  private shadow = this.game.querySelector(".ball-shadow");

  private gaugePivot = this.game.querySelector(".gauge-pivot");
  private gaugeGlow = this.game.querySelector(".gauge-glow");
  private gaugeBar = this.game.querySelector(".gauge-bar");

  constructor() {
    (<any>window).torwandClass = this;

    document.querySelector(".btn-mute").addEventListener("click", () => {
      this.toggleMute();
    });

    for (var i = 0; i < this.shotsTotal; i++) {
      $(".shots-display").append(`<span class="shot"></span>`);
    }

    this.toggleMute();
    this.initSounds();

    this.resetPlayer();
    this.initAutoAimRangeInputs();
    $(".test-buttons").css("display", "none");
    $(".game-container").css("display", "none");
    $(".section-torwand").css("min-height", "400px");
    $(".game-container").removeClass("vis");

    window.setTimeout(function () {
      (<any>window).initTorwandGameWithoutBall();
    }, 1000);

    ////// for testing only //////

    /*document
      .querySelector(".btn-reset")
      .addEventListener("click", () => this.resetBall(true));

    document.querySelector(".btn-replay").addEventListener("click", () => {
      this.resetBall();
      this.shoot();
    });

    document.querySelectorAll(".btn-shoot").forEach((btn, idx) =>
      btn.addEventListener("click", () => {
        this.resetBall();
        const settings = [
          { angle: 0, velocity: 0.75 + 0.25 * Math.random() },
          { angle: -10, velocity: 0.8 },
          { angle: 10, velocity: 0.68 },
          { angle: 17.65, velocity: 0.7 },
          { angle: 17.55, velocity: 0.5 },

          { angle: -16.725, velocity: 0.83 },
          { angle: 10, velocity: 0.70015 },
          { angle: 10, velocity: 0.70025 },
          { angle: -10.09, velocity: 0.7740941682921536 },
          { angle: 11.57, velocity: 0.5685220208329028 },

          { angle: -9.17, velocity: 0.7753420564431884 },
          { angle: -11.41, velocity: 0.8309739293775175 },
          { angle: -20 + 40 * Math.random(), velocity: 0.5 + 0.5 * Math.random() },
          { angle: -20 + 40 * Math.random(), velocity: 0.5 + 0.5 * Math.random() },
          { angle: -20 + 40 * Math.random(), velocity: 0.5 + 0.5 * Math.random() },
          { angle: -20 + 40 * Math.random(), velocity: 0.5 + 0.5 * Math.random() },
          { angle: -20 + 40 * Math.random(), velocity: 0.5 + 0.5 * Math.random() },
        ][idx];
        this.angle = ((90 + settings.angle) / 180) * Math.PI;
        this.velocity = settings.velocity;
        this.shoot();
      })
    );*/

    (<any>window).initTorwandGameWithoutBall = function () {
      $(".game-container").css("display", "block");
      $(".game-container").addClass("vis");
      $(".section-torwand").css("min-height", "auto");

      (<any>window).torwandClass.initCannon();
      (<any>window).torwandClass.animate();
      (<any>window).torwandClass.initSoccer();
      (<any>window).torwandClass.disableBall(); // disable and hide Ball at first
    };

    (<any>window).initTorwandGame = function () {
      $(".test-buttons").css("display", "block");

      $(".section-torwand #gameinstructions").css("display", "block");
      $("#gameinstructions A.playgame").on("click", function (e) {
        e.preventDefault();
        // (<any>window).torwandClass.unMute();

        (<any>window).torwandClass.sfxBgr.play();
        (<any>window).torwandClass.sfxBgr.fade(0, .05, 4000);

        (<any>window).torwandClass.sfxBgr2.play();
        (<any>window).torwandClass.sfxBgr2.fade(0, .25, 4000);

        if ($(this).hasClass("testmodus")) {
          (<any>window).torwandClass.enableProbeModus();
        } else {
          (<any>window).torwandClass.disableProbeModus();
        }
        (<any>window).torwandClass.resetShotsDone();
        (<any>window).torwandClass.enableBall();
        (<any>window).torwandClass.resetBall(true);
        $("#gameinstructions").fadeOut(250);
      })

      $(".goal").removeClass("goal-firstthree").removeClass("goal-lastthree");
      $(".goal").addClass("goal-firstthree");

      var offsetTop = $(".section-torwand").offset().top;
      offsetTop -= 195; /* 130 */

      if (parent === window) {
        $(".section-torwand").attr('data-stop', offsetTop);
        $(".section-torwand").attr('data-start', $(window).scrollTop());
        $(window).scrollTop(offsetTop);
        $(".section-torwand").css('perc', 0).animate({ perc: 1 }, {
          duration: 1000, step: function (fx, p) {
            var newv = Number($(this).attr('data-start')) - fx * (Number($(this).attr('data-start')) - Number($(this).attr('data-stop')));
            $(window).scrollTop(newv);
          }
        });
      } else {
        // iframe
        parent.postMessage(
          JSON.stringify({
            type: 'scrollto',
            offset_y: offsetTop
          }),
          '*'
        );
      }
    }
  }

  enableProbeModus() {
    this.probeModus = true;
  }

  disableProbeModus() {
    this.probeModus = false;
  }

  resetShotsDone() {
    this.shotsMade = [];
    this.shotsGood = 0;
    this.shotsBad = 0;
    $(".shots-display .shot").removeClass("hit").removeClass("missed");
  }

  getRandomInt(max) {
    return Math.floor(Math.random() * max);
  }

  showShotinDisplay() {
    var pos = this.shotsMade.length - 1;
    var goal = this.shotsMade[this.shotsMade.length - 1].goal;
    var wronghole = this.shotsMade[this.shotsMade.length - 1].wronghole;

    if (this.shotsMade.length <= 2) {
      $(".goal").addClass("goal-firstthree");
      $(".goal").removeClass("goal-lastthree");
    } else {
      $(".goal").removeClass("goal-firstthree");
      $(".goal").addClass("goal-lastthree");
    }

    if (this.probeModus) {
      if (goal) {
        this.shotsGood++;
        if (this.shotsMade.length === 1)
          $(".testmode-feedback").html("Perfekt, so funktioniert`s");
        else if (this.shotsMade.length === 5)
          $(".testmode-feedback").html("Wow, der hat gesessen. Jetzt der letzte auch noch...");
        else {
          var zuffi = this.getRandomInt(4);
          if (zuffi == 1)
            $(".testmode-feedback").html("Sehr gut! Weiter so.");
          else if (zuffi == 2)
            $(".testmode-feedback").html("Mega. Was für ein Schuss!");
          else if (zuffi == 3)
            $(".testmode-feedback").html("Top. Jetzt kann nichts mehr schiefgehen!");
          else
            $(".testmode-feedback").html("Wie ein Profi. Jetzt kann nichts mehr schiefgehen!");
        }
      } else if (wronghole) {
        this.shotsBad++;
        if (this.shotsMade.length <= 3)
          $(".testmode-feedback").html("Das war leider das falsche Loch... du musst rechts unten treffen!");
        else
          $(".testmode-feedback").html("Das war leider das falsche Loch... du musst links oben treffen!");
      } else {
        this.shotsBad++;
        if (this.shotsMade.length === 1)
          $(".testmode-feedback").html("Hmmm, das war leider nichts.");
        else if (this.shotsMade.length === 5)
          $(".testmode-feedback").html("Leider nicht. Einen Versuch hast du noch.");
        else {
          var zuffi = this.getRandomInt(4);
          if (zuffi == 1)
            $(".testmode-feedback").html("Das geht besser.");
          else if (zuffi == 2)
            $(".testmode-feedback").html("Der war leider nicht drin.");
          else if (zuffi == 3)
            $(".testmode-feedback").html("Vorbei ist leider vorbei!");
          else
            $(".testmode-feedback").html("Leider kein Tor, aber unter Wettkampfbedingungen klappts bestimmt gleich.");
        }
      }
      $(".testmode-feedback").stop(true, true).fadeIn(400).delay(2500).fadeOut(400);
    } else {
      if (goal) {
        this.shotsGood++;
        (<any>window).confettiClass.startConfetti();
      } else if (wronghole) {
        this.shotsBad++;
        if (this.shotsMade.length <= 3)
          $(".testmode-feedback").html("Das war leider das falsche Loch... du musst rechts unten treffen!");
        else
          $(".testmode-feedback").html("Das war leider das falsche Loch... du musst links oben treffen!");
        $(".testmode-feedback").stop(true, true).fadeIn(400).delay(2500).fadeOut(400);
      } else {
        this.shotsBad++;
      }
      $($(".shots-display .shot")[pos]).addClass("animate").addClass(goal ? "hit" : "missed");
    }

    if (this.shotsMade.length >= this.shotsTotal) {
      if (this.probeModus) {
        window.setTimeout(function () {
          (<any>window).torwandClass.removeBall();
        }, 1000);

        window.setTimeout(function () {
          $(".section-torwand #gameinstructions_second").css("display", "block");
          $("#gameinstructions_second A.playgame").on("click", function (e) {
            e.preventDefault();
            (<any>window).torwandClass.disableProbeModus();
            (<any>window).torwandClass.resetShotsDone();
            (<any>window).torwandClass.enableBall();
            (<any>window).torwandClass.resetBall(true);

            $(".goal").addClass("goal-firstthree");
            $(".goal").removeClass("goal-lastthree");

            $("#gameinstructions_second").fadeOut(250);
          })
        }, 2500);
      } else {
        window.setTimeout(function () {
          (<any>window).torwandClass.removeBall();
        }, 1000);

        window.setTimeout(function () {
          var goals = 0;
          for (var i = 0; i < (<any>window).torwandClass.shotsMade.length; i++) {
            if ((<any>window).torwandClass.shotsMade[i].goal) goals++;
          }

          $('#goalHits').val(goals);

          if (goals <= 0) {
            $("#participateModal").find(".hasgoals").css("display", "none");
            $("#participateModal").find(".hasnogoals").css("display", "block");
            $("#participateModal").find(".sponsored-default").css("display", "block");
            $("#participateModal").find(".sponsored-specific").css("display", "none");
          } else if (goals === 1) {
            $("#participateModal").find(".hasgoals").css("display", "block");
            $("#participateModal").find(".hasnogoals").css("display", "none");
            $("#participateModal").find(".sponsored-default").css("display", "none");
            $("#participateModal").find(".sponsored-specific").css("display", "block");
            $("#participateModal").find(".hasgoals").find(".goalsnr").html("EIN");
            $("#participateModal").find(".hasgoals").find(".goalsnr2").html("");            
          } else {
            $("#participateModal").find(".hasgoals").css("display", "block");
            $("#participateModal").find(".hasnogoals").css("display", "none");
            $("#participateModal").find(".sponsored-default").css("display", "none");
            $("#participateModal").find(".sponsored-specific").css("display", "block");
            $("#participateModal").find(".hasgoals").find(".goalsnr").html(goals + "-");
            $("#participateModal").find(".hasgoals").find(".goalsnr2").html("mit " + goals + "-facher Siegchance ");
          }

          $("#participateModal").modal("show");
          $("HTML").css("overflow", "hidden");

          $('#participateModal').on('hidden.bs.modal', function (e) {
            $("HTML").removeAttr("style");
          })
        }, 2500);
      }
    }
  }

  initSounds() {
    this.sfxBgr = new Howl({
      src: ["/assets/sfx/bgr.mp3"],
      loop: true,
      volume: 0.05,
    });

    this.sfxBgr2 = new Howl({
      src: ["/assets/sfx/birds.mp3"],
      loop: true,
      volume: 0.15,
    });

    this.sfxBall = new Howl({
      src: ["/assets/sfx/soccer-ball.mp3"]
    });

    this.sfxNoGoal = new Howl({
      src: ["/assets/sfx/keintor1.mp3"],
      volume: 1,
    });

    this.sfxWhistle = new Howl({
      src: ["/assets/sfx/whistle.mp3"],
      sprite: {
        whistle_0: [760, 1000],
        whistle_1: [3600, 1100],
        whistle_2: [6600, 1000],
        whistle_3: [9300, 1000],
      },
    });
  }

  toggleMute() {
    this.sfxMuted = !this.sfxMuted;
    Howler.mute(this.sfxMuted);
    document.querySelector(".btn-mute").innerHTML = !this.sfxMuted ? '&#128264;' : '	&#x1f507;';
  }

  unMute() {
    this.sfxMuted = false;
    Howler.mute(this.sfxMuted);
    document.querySelector(".btn-mute").innerHTML = !this.sfxMuted ? '&#128264;' : '	&#x1f507;';
  }

  playSfxAww() {
    var nr = this.shotsBad;
    var sfxGoal = new Howl({
      src: ["/assets/sfx/keintor" + ( nr + 1 ) + ".mp3"],
      volume: 0.4,
    });
    sfxGoal.play();
  }

  playSfxBall(body, ignoreZ = false) {
    // let spriteId = "kick_" + ~~(Math.random() * 4);
    let pos = body.position;
    let vol = Math.pow(body.velocity.norm() / this.FORCE, 2) * 2;
    // var id = this.sfxBall.play(spriteId);
    var id = this.sfxBall.play();
    this.sfxBall.volume(vol, id);
    this.sfxBall.pos(-pos.z, pos.y, ignoreZ ? 0 : pos.x, id);
  }

  playSfxCheers() {
    var nr = this.shotsGood;
    var sfxGoal = new Howl({
      src: ["/assets/sfx/tor" + ( nr + 1  ) + ".mp3"],
      volume: 0.4,
    });
    sfxGoal.play();

    var sfxGoal2 = new Howl({
      src: ["/assets/sfx/goal.mp3"],
      volume: 0.12,
    });
    sfxGoal2.play();
  }

  playSfxWhistle() {
    let spriteId = "whistle_" + ~~(Math.random() * 4);
    this.sfxWhistle.play(spriteId);
  }

  animate() {
    window.requestAnimationFrame((<any>window).torwandClass.animate);
    (<any>window).torwandClass.updatePhysics();
  }

  updatePhysics() {
    for (var i = 0; i < this.SUB_STEPS; ++i) {
      this.world.step(this.dt);
    }

    for (var i = 0; i !== this.meshes.length; i++) {
      this.meshes[i].position.copy(this.bodies[i].position);
      this.meshes[i].quaternion.copy(this.bodies[i].quaternion);
    }
  }

  initCannon() {
    // Setup our world
    this.world = new CANNON.World();
    this.world.quatNormalizeSkip = 0;
    this.world.quatNormalizeFast = false;

    this.world.gravity.set(0, -9.81, 0);
    this.world.broadphase = new CANNON.NaiveBroadphase();

    var ballShape = new CANNON.Sphere(this.ballRadius);
    this.ballBody = new CANNON.Body({
      mass: 0.44,
      angularDamping: 0.75,
      linearDamping: 0.01,
      material: new CANNON.Material({ friction: 1, restitution: 0.75 }),
    });

    this.ballBody.addEventListener("collide", function (e) {
      (<any>window).torwandClass.playSfxBall((<any>window).torwandClass.ballBody);
    });

    this.ballBody.addShape(ballShape);
    this.ballBody.position.set(0, this.ballRadius, 0);
    this.world.add(this.ballBody);
    this.bodies.push(this.ballBody);

    // Create a plane
    var groundShape = new CANNON.Plane();
    var groundBody = new CANNON.Body({
      mass: 0,
      material: new CANNON.Material({ friction: 1, restitution: 1 }),
    });
    groundBody.addShape(groundShape);
    groundBody.quaternion.setFromAxisAngle(
      new CANNON.Vec3(1, 0, 0),
      -Math.PI / 2
    );
    this.world.add(groundBody);

    // back plane
    var failPaneShape = new CANNON.Plane();
    var failPaneBody = new CANNON.Body({
      mass: 0,
      material: new CANNON.Material({ friction: 0, restitution: 0 }),
    });
    failPaneBody.addShape(failPaneShape);
    failPaneBody.quaternion.setFromAxisAngle(
      new CANNON.Vec3(0, 1, 0),
      Math.PI * 0.5
    );
    failPaneBody.position.set(-15, 0, 0);

    failPaneBody.collisionResponse = 0; // no impact on other bodys
    failPaneBody.addEventListener("collide", function (e) {
      var twObj = (<any>window).torwandClass;
      // console.log("ball is gone");
      if (!twObj.goalIsHit) {
        twObj.goalMissed();
      }
      twObj.resetBall(true);
    });

    this.world.add(failPaneBody);

    // hole hit objects (aka. the holes)

    // LEFT TOP
    var goalHitShapeLT = new CANNON.Box(new CANNON.Vec3(0.05, 0.275, 0.275));
    this.goalHitBodyLT = new CANNON.Body({
      mass: 0,
      material: new CANNON.Material({ friction: 0, restitution: 0 }),
    });
    this.goalHitBodyLT.addShape(goalHitShapeLT, new CANNON.Vec3(0, 1.345, 0.92));
    this.goalHitBodyLT.position.set(-11.1, 0, 0);
    this.goalHitBodyLT.collisionResponse = 0; // no impact on other bodys
    this.world.add(this.goalHitBodyLT);

    // RIGHT BOTTOM
    var goalHitShapeRB = new CANNON.Box(new CANNON.Vec3(0.05, 0.275, 0.275));
    this.goalHitBodyRB = new CANNON.Body({
      mass: 0,
      material: new CANNON.Material({ friction: 0, restitution: 0 }),
    });
    this.goalHitBodyRB.addShape(goalHitShapeRB, new CANNON.Vec3(0, 0.345, -0.92)); // rechts unten ...
    this.goalHitBodyRB.position.set(-11.1, 0, 0);
    this.goalHitBodyRB.collisionResponse = 0; // no impact on other bodys
    this.world.add(this.goalHitBodyRB);


    // Create the goal wall
    this.goalWallBody = new CANNON.Body({
      mass: 0, // 300,
      material: new CANNON.Material({ friction: 1, restitution: 1 }),
    });

    const goalDepth = 0.2 * 0.5;

    this.goalWallBody.addShape(
      new CANNON.Box(new CANNON.Vec3(goalDepth, 0.9, 0.09)),
      new CANNON.Vec3(0, 0.9, 1.285)
    );

    this.goalWallBody.addShape(
      new CANNON.Box(new CANNON.Vec3(goalDepth, 0.9, 0.09)),
      new CANNON.Vec3(0, 0.9, -1.285)
    );

    this.goalWallBody.addShape(
      new CANNON.Box(new CANNON.Vec3(goalDepth, 0.09, 1.375)),
      new CANNON.Vec3(0, 1.71, 0)
    );

    this.goalWallBody.addShape(
      new CANNON.Box(new CANNON.Vec3(goalDepth, 0.07, 1.375)),
      new CANNON.Vec3(0, 0.07, 0)
    );

    this.goalWallBody.addShape(
      new CANNON.Box(new CANNON.Vec3(goalDepth, 0.535, 1.01)),
      new CANNON.Vec3(0, 0.535, 0.365)
    );

    this.goalWallBody.addShape(
      new CANNON.Box(new CANNON.Vec3(goalDepth, 0.555, 1.01)),
      new CANNON.Vec3(0, 1.245, -0.365)
    );

    /*var quat = new CANNON.Quaternion();
    quat.setFromAxisAngle(new CANNON.Vec3(1, 0, 0), -Math.PI / 2);

    this.goalWallBody.addShape(
      new CANNON.Cylinder(0.02, 0.02, 1.8, 16),
      new CANNON.Vec3(0, 0.9, 1.45),
      quat
    );
    this.goalWallBody.addShape(
      new CANNON.Cylinder(0.02, 0.02, 1.8, 16),
      new CANNON.Vec3(0.4, 0.9, 1.785),
      quat
    );
    this.goalWallBody.addShape(
      new CANNON.Box(new CANNON.Vec3(0.02, 0.7, 0.1675)),
      new CANNON.Vec3(0.2, 1.1, -1.6175),
      new CANNON.Quaternion(0, 1, 0, Math.PI * 0.23)
    );

    this.goalWallBody.addShape(
      new CANNON.Cylinder(0.02, 0.02, 1.8, 16),
      new CANNON.Vec3(0, 0.9, -1.45),
      quat
    );
    this.goalWallBody.addShape(
      new CANNON.Cylinder(0.02, 0.02, 1.8, 16),
      new CANNON.Vec3(0.4, 0.9, -1.785),
      quat
    );
    this.goalWallBody.addShape(
      new CANNON.Box(new CANNON.Vec3(0.02, 0.7, 0.1675)),
      new CANNON.Vec3(0.2, 1.1, 1.6175),
      new CANNON.Quaternion(0, 1, 0, Math.PI * -0.23)
    );*/

    this.goalWallBody.position.set(-11, 0, 0);


    /// collision checks ///

    this.goalHitBodyLT.addEventListener("collide", function (e) {
      // console.log("ball hit the goal!", e);
      var twObj = (<any>window).torwandClass;
      if (twObj.shotsMade.length > 2) {
        twObj.goalSuccess();
      } else {
        twObj.currentShotWronghole = true;
      }
    });

    this.goalHitBodyRB.addEventListener("collide", function (e) {
      // console.log("ball hit the goal!", e);
      var twObj = (<any>window).torwandClass;
      if (twObj.shotsMade.length < 3) {
        twObj.goalSuccess();
      } else {
        twObj.currentShotWronghole = true;
      }
    });


    this.goalWallBody.addEventListener("collide", function (e) {
      var twObj = (<any>window).torwandClass;
      if (twObj.lastWallHit != twObj.frameNumber) {
        // console.log("goal wall collided");
        twObj.playSfxBall(twObj.ballBody, true);
      }
      twObj.lastWallHit = twObj.frameNumber;
    });

    this.bodies.push(this.goalWallBody);
    this.world.add(this.goalWallBody);
  }

  ballFailTest() {
    if (this.goalIsHit === null) {
      if (this.ballBody.velocity.x > 0) {
        // bounced back
        this.goalMissed(Math.random());
      }
    }
  }

  goalMissed(frustration = 1) {
    this.goalIsHit = false;
    // frustration > 0.7 ? this.playSfxAww() : null;
    this.playSfxAww();
    this.shotsMade.push({ "goal": false, "wronghole": this.currentShotWronghole });
    this.currentShotWronghole = false;
    this.showShotinDisplay();
  }

  goalSuccess() {
    this.goalIsHit = true;
    this.playSfxCheers();
    // this.playSfxWhistle();
    this.shotsMade.push({ "goal": true, "wronghole": false });
    this.showShotinDisplay();
  }

  resetBall(animation = false) {
    if (this.ballResetTimeout) clearTimeout(this.ballResetTimeout);

    if (this.shotsMade.length >= this.shotsTotal) return;

    this.ballRotation = 0;
    this.ballBody.angularVelocity.set(0, 0, 0);
    this.ballBody.velocity.set(0, 0, 0);
    this.ballBody.position.set(0, this.ballRadius, 0);

    if (animation === true) {
      this.ball.classList.remove("fx-in");
      this.ball.classList.remove("fx-out");
      setTimeout(() => this.ball.classList.add("fx-in"), 0);
      setTimeout(() => this.playSfxWhistle(), 500);
    }
    this.goalIsHit = null;
  }

  efh() {
    var twObj = (<any>window).torwandClass;
    ++twObj.frameNumber;
    window.requestAnimationFrame(twObj.efh);

    const pixelPerMeter = 100;

    const ballPos = twObj.ballBody.position;
    const bp = {
      x: -ballPos.z * pixelPerMeter,
      y: -(ballPos.y - twObj.ballRadius) * pixelPerMeter, // pivots differ
      z: ballPos.x * pixelPerMeter,
    };

    const SCALE = 6.81; // textures have to be scaled for crispiness and 3d effectiveness - so the x,y coordinates have to be scaled as well (have a look in the stylesheet)
    const bpp = {
      x: -ballPos.z * pixelPerMeter * SCALE,
      y: -(ballPos.y - twObj.ballRadius) * pixelPerMeter * SCALE, // pivots differ
      z: ballPos.x * pixelPerMeter,
    };

    const gp = { x: 0, y: 0, z: -11 * pixelPerMeter };

    $(twObj.goalPivot).css(`transform`, `translate3D(${~~gp.x}px, 0, ${~~gp.z}px)`);
    $(twObj.goalPivot).css(`z-index`, bp.z < gp.z ? 10 : 2);

    $(twObj.ballPivot).css(`transform`, `translate3D(${~~bpp.x}px, ${~~bpp.y}px, ${~~bpp.z}px)`);

    twObj.ballRotation +=
      -twObj.ballBody.angularVelocity.x * (1 / 60) +
      twObj.ballBody.angularVelocity.z * (1 / 60) * 0.2 -
      twObj.ballBody.angularVelocity.y * (1 / 60) * 0.2;

    $(twObj.ball).css(`transform`, `translate(-50%, -100%) rotate(${~~(twObj.ballRotation * 100) * 0.01
      }rad)`);

    const shadowScale = Math.max(0, 1 - -bp.y / 300);
    $(twObj.shadowPivot).css(`transform`, `translate3D(${~~bpp.x + -0.75 * bp.y
      }px, 0, ${~~bp.z}px)`);
    $(twObj.shadow).css(`opacity`, shadowScale);

    twObj.ballFailTest();
  }

  ////// controls //////

  resetPlayer() {
    this.angle = Math.PI * .5;
    this.velocity = 0;
  }

  mdh() {
    var twObj = (<any>window).torwandClass;
    twObj.resetBall();
    twObj.resetPlayer();
    window.addEventListener("mouseup", twObj.muh);
    window.addEventListener("mousemove", twObj.mmh);
    window.document.body.classList.add('ball-active');
  }

  muh() {
    var twObj = (<any>window).torwandClass;
    window.removeEventListener("mouseup", twObj.muh);
    window.removeEventListener("mousemove", twObj.mmh);
    twObj.resetGauge();
    twObj.shoot();
    window.document.body.classList.remove('ball-active');
  }

  mmh(e) {
    e.preventDefault();
    var twObj = (<any>window).torwandClass;
    twObj.setGauge(e);
  }

  tsh(e) {
    var twObj = (<any>window).torwandClass;
    e.preventDefault();
    if (!twObj.touch) {
      let t = e.changedTouches[0];
      twObj.touch = t;
      twObj.resetPlayer();
      window.addEventListener("touchend", twObj.teh);
      window.addEventListener("touchmove", twObj.tmh);
      window.document.body.classList.add('ball-active');
    }
  }

  tmh(e) {
    var twObj = (<any>window).torwandClass;

    let t = [...e.changedTouches].filter(
      (ct) => ct.identifier === twObj.touch.identifier
    )[0];
    if (t) {
      twObj.setGauge(t);
    }
  }

  teh(e) {
    var twObj = (<any>window).torwandClass;

    let t = [...e.changedTouches].filter(
      (ct) => ct.identifier === twObj.touch.identifier
    )[0];
    if (t) {
      window.removeEventListener("touchend", twObj.teh);
      window.removeEventListener("touchmove", twObj.tmh);
      twObj.touch = null;
      twObj.resetGauge();
      twObj.shoot();
      window.document.body.classList.remove('ball-active');
    }
  }

  notAllowed(e) {
    if ((<any>window).torwandClass.shotsMade.length >= (<any>window).torwandClass.shotsTotal) {
      alert("Du darst leider nur " + (<any>window).torwandClass.shotsTotal + " mal schießen!");
    } else {
      var offsetTop = $("#participation").offset().top;
      if (parent === window) {
        $("#participation").attr('data-stop', offsetTop);
        $("#participation").attr('data-start', $(window).scrollTop());
        $(window).scrollTop(offsetTop);
        $("#participation").css('perc', 0).animate({ perc: 1 }, {
          duration: 1000, step: function (fx, p) {
            var newv = Number($(this).attr('data-start')) - fx * (Number($(this).attr('data-start')) - Number($(this).attr('data-stop')));
            $(window).scrollTop(newv);
          }
        });
      } else {
        // im Iframe
        parent.postMessage(
          JSON.stringify({
            type: 'scrollto',
            offset_y: offsetTop
          }),
          '*'
        );
      }
    }
  }

  setGauge(e) {
    this.gaugeBar.classList.add("active");
    let origin = this.gaugePivot.getBoundingClientRect();
    let dx = e.clientX - origin.left;
    let dy = e.clientY - origin.top;
    let dist = Math.sqrt(dx ** 2 + dy ** 2);
    let scale = (this.velocity = Math.max(
      0,
      Math.min(dist / this.gaugeBar.clientWidth, 1)
    ));
    this.angle = Math.atan2(dy, dx);

    $(this.gaugeGlow).css(`opacity`, Math.max(0, Math.min(scale * 0.75 + 0.25, 1)));
    $(this.gaugeBar).css(`transform`, `translate(0, -50%) rotate(${this.angle}rad) scale(${scale}, 1)`);
  }

  resetGauge() {
    $(this.gaugeGlow).css(`opacity`, 0);
    $(this.gaugeBar).css(`transform`, `translate(0, -50%) rotate(${this.angle}rad) scale(0, 1)`);
    this.gaugeBar.classList.remove("active");
  }

  initSoccer() {
    /*this.ball.addEventListener("touchstart", this.tsh);
    this.ball.addEventListener("mousedown", this.mdh);*/
    window.requestAnimationFrame(this.efh);
  }

  disableBall() {
    this.ball.removeEventListener("touchstart", this.tsh);
    this.ball.removeEventListener("mousedown", this.mdh);
    this.ball.addEventListener("touchstart", this.notAllowed);
    this.ball.addEventListener("mousedown", this.notAllowed);
    $(".ball").addClass("disabled");
  }

  removeBall() {
    this.ball.removeEventListener("touchstart", this.tsh);
    this.ball.removeEventListener("mousedown", this.mdh);
    $(".ball").addClass("nomore");
    $(".ball-shadow").addClass("nomore");
  }

  enableBall() {
    this.ball.removeEventListener("touchstart", this.notAllowed);
    this.ball.removeEventListener("mousedown", this.notAllowed);
    this.ball.addEventListener("touchstart", this.tsh);
    this.ball.addEventListener("mousedown", this.mdh);
    $(".ball").removeClass("disabled");
    $(".ball").removeClass("nomore");
    $(".ball-shadow").removeClass("nomore");
  }

  shoot() {
    // console.log(
    //   "shoot",
    //   this.angle,
    //   ((this.angle / Math.PI) * 180 - 90).toFixed(2),
    //   this.velocity
    // );

    // this.updateAutoAimParams();
    const aimed = this.autoAim();

    let a = aimed.angle;
    let v = aimed.velocity * this.FORCE;
    this.ballBody.velocity.x -= Math.sin(a) * v * 1; // depth
    this.ballBody.velocity.y += v * 0.2; // vertical
    this.ballBody.velocity.z += Math.cos(a) * v * 0.5; // horizontal

    this.startBallResetTimer();
    this.playSfxBall(this.ballBody);
  }

  initAutoAimRangeInputs() {
    $(".range-autoaim-amount").val(this.autoAimAmount);
    $(".range-autoaim-distance-angle").val(this.autoAimDistanceAngular);
    $(".range-autoaim-distance-velocity").val(this.autoAimDistanceVelocity);
    $(".range-autoaim-curve-angle").val(this.autoAimCurveAngle);
    $(".range-autoaim-curve-velocity").val(this.autoAimCurveVelocity);
  }

  updateAutoAimParams() {
    this.autoAimAmount =
      (document.querySelector(".range-autoaim-amount") as HTMLInputElement)?.valueAsNumber ?? 0;
    this.autoAimDistanceAngular =
      (document.querySelector(".range-autoaim-distance-angle") as HTMLInputElement)?.valueAsNumber ??
      10;
    this.autoAimDistanceVelocity =
      (document.querySelector(".range-autoaim-distance-velocity") as HTMLInputElement)?.valueAsNumber ??
      1;
    this.autoAimCurveAngle =
      (document.querySelector(".range-autoaim-curve-angle") as HTMLInputElement)?.valueAsNumber ?? 1;
    this.autoAimCurveVelocity =
      (document.querySelector(".range-autoaim-curve-velocity") as HTMLInputElement)?.valueAsNumber ?? 1;
  }

  autoAim() {
    const targetIdx = (this.angle / Math.PI + 0.5 + 2) % 2 < 1 ? 0 : 1; // left = 0 or right = 1 goal
    const targets = [
      { angle: -10, velocity: 0.8 },
      { angle: 10, velocity: 0.68 },
    ];

    const autoAimDistanceAngularRad =
      ((this.autoAimDistanceAngular) / 180) * Math.PI;
    const tAngle = ((90 + targets[targetIdx].angle) / 180) * Math.PI;
    const tVelocity = targets[targetIdx].velocity;

    const autoAimStrengthAngle = this.curveFalloff(
      (this.angle - tAngle) / Math.max(0.000000001, autoAimDistanceAngularRad),
      this.autoAimCurveAngle
    );

    const autoAimStrengthVelocity = this.curveFalloff(
      (this.velocity - tVelocity) / Math.max(0.000000001, this.autoAimDistanceVelocity),
      this.autoAimCurveVelocity
    );

    return {
      angle: this.lerp(this.angle, tAngle, this.autoAimAmount * autoAimStrengthAngle),
      velocity: this.lerp(
        this.velocity,
        tVelocity,
        this.autoAimAmount * autoAimStrengthVelocity
      ),
    };
  }

  curveFalloff(val, pow) {
    const clipped = Math.max(0, Math.min(Math.abs(val), 1));
    return Math.pow(1 - clipped, pow);
  }

  lerp(a, b, n) {
    return (1 - n) * a + n * b;
  }

  startBallResetTimer() {
    if (this.ballResetTimeout) clearTimeout(this.ballResetTimeout);
    this.ballResetTimeout = setTimeout(() => {
      this.ball.classList.remove("fx-in");
      this.ball.classList.add("fx-out");
      setTimeout(() => this.resetBall(true), 250);
    }, 5000);
  }

}
