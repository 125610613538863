import "core-js/stable"
import "regenerator-runtime/runtime";

import $ from 'jquery';
(<any>window).jQuery = $; 7

import { IFrameSupport } from './iframe-support';
(<any>window).$ifs = new IFrameSupport();

import { Captcha } from './captcha';
new Captcha('[data-captcha-container]', '/captcha');

import { Validator } from './validator';
new Validator();

import { ValidatorFreeTicket } from './validatorFreeTicket';
new ValidatorFreeTicket();

import { ValidatorParticipation } from './validatorParticipation';
new ValidatorParticipation();

import { Torwand } from './torwand';
new Torwand();

import { Confetti } from './confetti';
new Confetti();

// import 'bootstrap/js/dist/util';
// import 'bootstrap/js/dist/alert';
// import 'bootstrap/js/dist/button';
// import 'bootstrap/js/dist/carousel';
// import 'bootstrap/js/dist/collapse';
// import 'bootstrap/js/dist/dropdown';
import 'bootstrap/js/dist/modal';
// import 'bootstrap/js/dist/popover';
// import 'bootstrap/js/dist/scrollspy';
// import 'bootstrap/js/dist/tab';
// import 'bootstrap/js/dist/toast';
// import 'bootstrap/js/dist/tooltip';

$(function () {

  // if (parent === window){
  //   $(".game-container").css("height", $(window).height() );

    // $(window).on("resize",function(){
    //   $(".game-container").css("height", $(window).height() );
    // });
  // }

  $(document).on('hidden.bs.modal', '.modal', function () {
    $('.modal:visible').length && $(document.body).addClass('modal-open');
  });

  $(".toggleFAQ").on("click",function(e){
    e.preventDefault();
    $("#faqModal").modal("show");
  })

  $(".toggleTNB").on("click",function(e){
    e.preventDefault();
    $("#tnbModal").modal("show");
  })

  /*$(".difficulty-buttons A.btn").on("click", function (e) {
    e.preventDefault();
    var autoAimAmount = 1;
    var autoAimDistanceAngular = 7;
    var autoAimDistanceVelocity = 0.2;
    var autoAimCurveAngle = .33;
    var autoAimCurveVelocity = .25;

    $(".difficulty-buttons A.btn").removeClass("active");
    $(this).addClass("active");
    if ($(this).attr("data-help-level") === "0") {
      autoAimAmount = 0;
      autoAimDistanceAngular = 0;
      autoAimDistanceVelocity = 0;
      autoAimCurveAngle = 0;
      autoAimCurveVelocity = 0;
    }else if ($(this).attr("data-help-level") === "1") {
      autoAimAmount = 1;
      autoAimDistanceAngular = 3.5;
      autoAimDistanceVelocity = 0.1;
      autoAimCurveAngle = .165;
      autoAimCurveVelocity = .125;
    }else if ($(this).attr("data-help-level") === "2") {
      autoAimAmount = 1;
      autoAimDistanceAngular = 7;
      autoAimDistanceVelocity = 0.2;
      autoAimCurveAngle = .33;
      autoAimCurveVelocity = .25;
    }else if ($(this).attr("data-help-level") === "3") {
      autoAimAmount = 1;
      autoAimDistanceAngular = 14;
      autoAimDistanceVelocity = 0.35;
      autoAimCurveAngle = .4;
      autoAimCurveVelocity = .30;
    }else if ($(this).attr("data-help-level") === "4") {
      autoAimAmount = 1;
      autoAimDistanceAngular = 21;
      autoAimDistanceVelocity = 0.5;
      autoAimCurveAngle = .5;
      autoAimCurveVelocity = .35;
    }else if ($(this).attr("data-help-level") === "5") {
      autoAimAmount = 1;
      autoAimDistanceAngular = 28;
      autoAimDistanceVelocity = 0.7;
      autoAimCurveAngle = .6;
      autoAimCurveVelocity = .4;
    }
    $(".range-autoaim-amount").val(autoAimAmount);
    $(".range-autoaim-distance-angle").val(autoAimDistanceAngular);
    $(".range-autoaim-distance-velocity").val(autoAimDistanceVelocity);
    $(".range-autoaim-curve-angle").val(autoAimCurveAngle);
    $(".range-autoaim-curve-velocity").val(autoAimCurveVelocity);
  })*/

  $(".nocodetoggle").on("click", function (e) {
    e.preventDefault();
    if ($("#nocodeModal").css("display") == "none")
      $("#nocodeModal").modal("show");
    else
      $("#nocodeModal").modal("hide");
  })

  $(".howtotoggle").on("click", function (e) {
    e.preventDefault();
    if ($("#howtoModal").css("display") == "none")
      $("#howtoModal").modal("show");
    else
      $("#howtoModal").modal("hide");
  })

  // fixed menubar
  $(window).scroll(function () {
    if ($(window).scrollTop() <= 0)
      $("HEADER").removeClass("sticky");
    else if (!$("HEADER").hasClass("sticky"))
      $("HEADER").addClass("sticky");
  });

  // Jump-Link
  const SMOOTH_SCROLL_ATTR = 'data-smooth-scroll';
  $(`[${SMOOTH_SCROLL_ATTR}]`).click(function (e) {
    const $currentTarget = $(e.currentTarget);
    const $href = $currentTarget.attr('href');

    var offsetTop = $($href).offset().top;
    if (!offsetTop) return;

    offsetTop -= 65; if (offsetTop < 0) offsetTop = 0;

    e.preventDefault();
    const duration = parseInt($currentTarget.attr(SMOOTH_SCROLL_ATTR)) || 1000;

    $(this).attr('data-stop', offsetTop);
    $(this).attr('data-start', $(window).scrollTop());
    $(window).scrollTop(offsetTop);
    $(this).css('perc', 0).animate({ perc: 1 }, {
      duration: duration, step: function (fx, p) {
        var newv = Number($(this).attr('data-start')) - fx * (Number($(this).attr('data-start')) - Number($(this).attr('data-stop')));
        $(window).scrollTop(newv);
      }, complete: function () {
        // window.location.hash = $href.replace(/^#/, '');
      }
    });
  });
});


// $(function () {

//   $checkcodeForm.on('win.aldi.checkcode', function (event) {
//     $('[data-participation-token]').val(event.participationToken);
//   });

//   const $recordWinnerForm = $('[data-send-ajax="record-winner"]');

//   $recordWinnerForm.on('complete.aldi.record-winner', function () {
//     const $form = $(this);
//     // refresh captcha on complete
//     $form.find('[data-captcha-container]').captcha('refresh');
//   });

//   const $subscribeNewsletterForm = $('[data-send-ajax="subscribe-newsletter"]');

//   $subscribeNewsletterForm.on('complete.aldi.subscribe-newsletter', function () {
//     const $form = $(this);
//     // refresh captcha on complete
//     $form.find('[data-captcha-container]').captcha('refresh');
//   });

  // });
