import $ from 'jquery';

/**
 * This adds support for messaging between this page and the the iframe-host page
 * Both pages will exchange information about window size and scroll position.
 * The later will be stored in the global viewOffset variable to set the vertical position of opening modals.
 */
export class IFrameSupport {

  private connected = false;

  constructor() {
    this.init();
    $(document).ready(this.init);
  }

  getLuckyCode = () => {
    if (parent === window)
      return;
    parent.postMessage(
      JSON.stringify({
        type: 'getluckycode'
      }),
      '*'
    );
  }

  showNewsletteroverlay = (hashvalue) => {
    if (parent === window)
      return;
    parent.postMessage(
      JSON.stringify({
        type: 'newsletter',
        h: hashvalue
      }),
      '*'
    );
  }

  getFiliale = () => {
    if (parent === window)
      return;

    parent.postMessage(
      JSON.stringify({
        type: 'getfiliale'
      }),
      '*'
    );
  }

  resizeHandler = (e?: Event) => {
    if (parent === window) return;

    parent.postMessage(
      JSON.stringify({
        type: 'resize',
        w: $('html').width(),
        h: $('html').height()
      }),
      '*'
    );

    // Torwand aktualisieren
    var vh = window['viewOffset'].th || window.top?.innerHeight || window.innerHeight;
    // var vw = window['viewOffset'].tw || window.top?.innerWidth  || window.innerWidth ;
    // var ratio = vw/vh;
    // var flipRatio = 1;

    // console.log('update game height', ratio < flipRatio ? 'PORTRAIT':'LANDSCAPE', vw, vh, ratio, window['viewOffset'], window['viewOffset'].th)

    // if (vh) {
    //   if (vw/vh > flipRatio) {
    //     // portraitMode
    $(".game-container").css("height", .6 * vh);
    // $(".section-torwand").css({
    //   "padding-top": .2 * vh * (1 / Math.min(1, vh/1200)) * Math.min(1, vw / 2000)
    // });
    $(".section-torwand .camera").css({
      "top": "calc(50% + " + .2 * vh + "px)",
      "perspective-origin": "50% calc(" + (-.5 * vh) + "px + 100px)"
    });
    $(".section-torwand .gauge-bar").css("min-width", .3 * vh);
    $("#confetti-canvas").css({
      "top": -window['viewOffset'].ty + "px",
      "height": vh + "px"
    });

    //   } else {
    //     // landscapeMode
    //     $(".game-container").css("height", vh);
    //     $(".section-torwand .gauge-bar").css("min-width", .3 * vh);
    //   }
    // }
  }

  modalHandler = (e?: Event) => {
    if (parent === window) return;

    // Modal aktualisieren
    $(".modal-dialog").each(function () {
      var modal = $(this).closest(".modal");
      var prev = modal.css("display");
      modal.css("display", "block");
      var mH = $(this).outerHeight();
      modal.css("display", prev); // restore prev state
      $(this).css("top", -window['viewOffset'].ty + (window['viewOffset'].th - mH) / 2);
    });
  }

  messageHandler = (e: any) => {
    if (!e.data || e.data === undefined || e.data.length === 0 || typeof e.data != "string" || e.data.substring(0, 1) != "{") return;  // Komische Events abfangen

    var obj = JSON.parse(e.data);
    if (obj && obj.type) {
      if (obj.type === 'reposition') {
        window['viewOffset'].tx = obj.tx;
        window['viewOffset'].ty = obj.ty;
        window['viewOffset'].th = obj.th;
        window['viewOffset'].tw = obj.tw;
        window['viewOffset'].updated = new Date();
        this.resizeHandler();

        // request until the data is here
        if (!window['storeFound']) {
          this.getFiliale();
        }
      } else if (obj.type === 'filialinfo') {
        window['storeFound'] = true;
        window['storeId'] = obj.storeid;
        window['storeName'] = obj.storename;
        $('#storeid').val(obj.storeid);
      } else if (obj.type === 'luckycode') {
        $("#code").val(obj.code);
      }
    }

    if (!this.connected) {
      this.connected = true;
      $('html,body').css('overflow-y', 'hidden');
    }
  }

  init = () => {
    /*if (parent === window && document.location.href.indexOf("localhost") < 0) {
      // weiterleiten
      return;
    }*/

    window['viewOffset'] = { tx: 0, ty: 0, th: 0, tw: 0, updated: null };
    window['storeFound'] = false;
    window['storeId'] = -1;
    window['storeName'] = '';

    if (window.postMessage && JSON && parent !== window) {
      var eventMethod = window.addEventListener ? "addEventListener" : "attachEvent";
      var eventer = window[eventMethod];
      var messageEvent = (eventMethod === "attachEvent") ? "onmessage" : "message";

      eventer(messageEvent, this.messageHandler);
      eventer('resize', this.resizeHandler);
      $('.collapse').on('hidden.bs.collapse shown.bs.collapse', this.resizeHandler);

      $('.modal').on('show.bs.modal', this.modalHandler);

      window.setTimeout(this.resizeHandler, 510);
    }

    // Newsletter an Parent übergeben
    if (parent === window) return;
    var $ifrObj = this;
    $('#newsletter').off('click');
    $('#newsletter').on('click', function (e) {
      var checked = $(this).prop("checked");
      if (checked) {
        var tempEmail = $('#email').val();
        $ifrObj.showNewsletteroverlay('df5a532d-3a13-48df-b3a4-dba7b2b62fea|SLIDE_IN_DOWN?emailAddress=' + tempEmail);
      }
    });

    // Code üergeben?!?
    window.setTimeout(function ($ifrObj) {
      $ifrObj.getLuckyCode();
    }, 520, this);
  }
}
